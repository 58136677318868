import { Box } from '@opendoor/bricks-next';
import { Flex, Link, Text } from '@opendoor/bricks/core';
import { Entry } from 'contentful';

import { fetchEntriesById } from 'cms/api';

import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import { IComponentNavBar, ITopicPageFields } from '../../../declarations/contentful';

const renderNavBar = (
  _entry: IComponentNavBar,
  resolvedData?: Awaited<ReturnType<typeof navBarLoader>>,
) => {
  if (!resolvedData?.navBarItems || resolvedData?.navBarItems.length === 0) {
    return null;
  }

  return (
    <Box my="$7x">
      <Flex
        flexWrap="wrap"
        gap={[5, 5, 6]}
        backgroundColor={['unset', 'unset', 'neutrals20']}
        borderRadius="roundedSquare"
        px={5}
        py={4}
        justifyContent={['center', 'center', 'flex-start']}
        alignItems="center"
      >
        {resolvedData?.navBarItems.map(({ displayText, slug }, index) => {
          if (!displayText) {
            return null;
          }
          if (resolvedData?.currentSlug === slug) {
            return (
              <Text
                fontWeight={['semibold', 'semibold', 'unset']}
                color="neutrals0"
                backgroundColor="brand50"
                boxShadow={['z1', 'z1', 'unset']}
                py={[4, 4, 2]}
                px={[6, 6, 5]}
                borderRadius={['roundedSmooth', 'roundedSmooth', 'roundedSquare']}
              >
                {displayText}
              </Text>
            );
          }
          return (
            displayText &&
            slug && (
              <Link
                boxShadow={['z1', 'z1', 'unset']}
                py={[4, 4, 0]}
                px={[6, 6, 0]}
                borderRadius="roundedSmooth"
                key={`nav-bar-item-${index}-${slug}`}
                analyticsName="cosmos-topic-page-nav-item-link"
                aria-label={`Go to ${displayText} page`}
                href={slug}
              >
                <Text color="neutrals90">{displayText}</Text>
              </Link>
            )
          );
        })}
      </Flex>
    </Box>
  );
};

const navBarLoader = async (input: IComponentNavBar, root?: Entry<any>) => {
  const currentSlug = root?.fields?.slug;
  if ((input.fields?.items ?? []).length === 0) {
    return {
      navBarItems: [],
      currentSlug,
    };
  }

  const topicPageSysIds: Array<string> = [];
  input?.fields?.items?.forEach((item) => {
    if (item?.fields?.topicPage?.sys?.type === 'Link' && item?.fields?.topicPage?.sys?.id) {
      topicPageSysIds.push(item?.fields?.topicPage?.sys?.id);
    }
  });

  const response = await fetchEntriesById<ITopicPageFields>('topicPage', topicPageSysIds, {
    select: 'sys.id,fields.slug,fields.topicName',
  });
  const topicPageResponseSysIds = response?.map((item) => item?.sys?.id);

  const navBarItems = input?.fields?.items?.map((item) => {
    let slug = item?.fields?.url || item?.fields?.topicPage?.fields?.slug;
    if (
      item?.fields?.topicPage?.sys?.id &&
      topicPageResponseSysIds?.includes(item?.fields?.topicPage?.sys?.id)
    ) {
      slug = response?.find((r) => r?.sys?.id === item?.fields?.topicPage?.sys?.id)?.fields?.slug;
    }
    return {
      displayText: item?.fields?.displayText,
      slug,
    };
  });
  return {
    navBarItems,
    currentSlug,
  };
};

const NavBar: EntryComponent<IComponentNavBar, Awaited<ReturnType<typeof navBarLoader>>> = {
  render: renderNavBar,
  loader: navBarLoader,
};

export default NavBar;
