import { FC, useState } from 'react';

import {
  Box,
  Button,
  Flex, // TODO: Label should not be used directly and should stay as an internal utility for bricks form components. Please update the usage of Label.
  // eslint-disable-next-line no-restricted-imports
  Label,
  Text,
} from '@opendoor/bricks/core';
import Input, { InputProps } from '@opendoor/bricks/core/Input';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';

import { useObservability } from '../../helpers/observability';

export type EmailInputProps = {
  campaignName: string;
  ctaText?: string;
};

/* storybook-check-ignore */
export const EmailInput: FC<EmailInputProps> = (props) => {
  const { trackEvent } = useObservability();
  const [email, setEmail] = useState('');
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { isMobile } = useDevice();
  const isFromTopicPage = props.campaignName === 'topicPage';

  const onEmailChange: InputProps['onChange'] = (_event, newValue, validFormat) => {
    setEmail(newValue || '');
    setIsSubmitError(false);
    setIsSubmitted(false);
    setIsValidEmail(validFormat || false);
  };

  const onEmailSubmit = async () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'subscribe-to-mailing-list', undefined, {
      categoryOverride: 'homepage',
    });
    if (!isValidEmail) {
      setIsSubmitError(true);
      return;
    }
    setIsSubmitting(true);
    setIsSubmitError(false);
    const res = await fetch('/api/v1/marketing/inbound-campaign-email-capture', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ campaign_name: props.campaignName, email }),
    });
    if (res.ok) {
      setIsSubmitError(false);
      setIsSubmitting(false);
      setIsSubmitted(true);
      return;
    }
    setIsSubmitting(false);
    setIsSubmitError(true);
    setIsSubmitted(false);
  };

  return (
    <>
      {!isFromTopicPage && (
        <Label display="inline-block" mb="4" color="neutrals0" htmlFor="call-to-action-email">
          Enter your email address
        </Label>
      )}
      <Flex
        flexDir="column"
        backgroundColor={[null, null, 'neutrals0']}
        borderRadius="roundedSquare"
      >
        <Flex alignItems="center" flexDir={['column', null, 'row']}>
          <Input
            analyticsName="cosmos-email-input-submit-email-to-receive-tips"
            mt={3}
            padding={4}
            id="call-to-action-email"
            type="email"
            aria-label="email"
            value={email}
            border="none"
            borderRadius="roundedSquare"
            unboundedWidth={true}
            disabled={isSubmitting}
            name="call-to-action-email"
            onChange={onEmailChange}
            autoComplete="email"
            _focus={{ outline: 'none' }}
          />
          <Box width={['100%', null, 'inherit']}>
            <Button
              backgroundColor={['neutrals100', null, 'brand50']}
              fontSize="s0"
              width={['100%', null, '175px']}
              mt={[3, null, 0]}
              mx={[0, null, 3]}
              onClick={onEmailSubmit}
              disabled={isSubmitted}
              success={isSubmitted && !isSubmitError ? true : undefined}
              loading={isSubmitting}
              loadingText="Subscribing..."
              size={isMobile ? 'md' : 'sm'}
              aria-label={isSubmitted ? '' : 'Submit email to recieve tips'}
              analyticsName="cosmos-email-input-submit-email-to-receive-tips"
            >
              {isSubmitted ? 'Submitted' : props.ctaText || 'Get tips and trends'}
            </Button>
          </Box>
        </Flex>
        {isSubmitError && (
          <Text
            fontSize="s0"
            lineHeight="s00"
            ml={4}
            mb={3}
            mt={[4, null, 0]}
            color={['neutrals0', null, 'red50']}
          >
            Sorry, that didn't go through, please check your email address and submit again.
          </Text>
        )}
      </Flex>
    </>
  );
};
