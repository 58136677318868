import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Text, useMedia } from '@opendoor/bricks-next';
import { Flex } from '@opendoor/bricks/core';

import { EntryComponent } from '../../../cms/entries/entries';
import { EmailInput } from '../../../components/shared/EmailInput';
import { H3 } from '../../../components/shared/Heading';
import { IComponentEmailHero } from '../../../declarations/contentful';
import typography from '../typography';

const EmailHeroComponent = (entry: IComponentEmailHero) => {
  const { fields } = entry;
  const title = fields?.h1 ? documentToReactComponents(fields?.h1) : null;
  const subtitle = fields?.h2 ? documentToReactComponents(fields?.h2) : null;
  const desktopHeroImage = fields?.desktopImage?.fields?.file?.url;
  const mobileHeroImage = fields?.mobileImage?.fields?.file?.url;

  const { largerThanSM } = useMedia();

  return (
    <Flex justifyContent="space-between" flexDir={['column', null, 'row']}>
      <Box
        borderRadius="$10x"
        width="100%"
        minHeight={580}
        marginVertical="$6x"
        $largerThanSM={{
          marginVertical: '$8x',
          minHeight: 360,
          marginBottom: 0,
        }}
        padding="$16x"
        marginBottom="$6x"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: largerThanSM ? `url(${desktopHeroImage})` : `url(${mobileHeroImage})`,
        }}
      >
        <Flex flexDir="column" height="100%" justifyContent="space-between">
          <Flex flexDir="column">
            <H3 {...typography.h3} color="neutrals100" mb={7}>
              {title}
            </H3>
          </Flex>
          <Box width="100%" $largerThanSM={{ width: '50%' }} py="$4x">
            <EmailInput campaignName="topicPage" ctaText="Sign up" />
          </Box>
          <Text marginTop="$16x" tag="p" typography="$bodySmall" color="$contentTertiary">
            {subtitle}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

const EmailHero: EntryComponent<IComponentEmailHero> = {
  render: EmailHeroComponent,
};

export default EmailHero;
