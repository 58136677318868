import { ReactNode, useState } from 'react';

import { Text } from '@opendoor/bricks-next';
import { Avatar } from '@opendoor/bricks/complex';
import { Flex, Link } from '@opendoor/bricks/core';

import SocialIcon from 'components/shared/icons/SocialIcon';

import { EntryComponent, IRenderContext } from '../../../cms/entries/entries';
import {
  IAuthorFields,
  IComponentExperts,
  IComponentRelatedArticles,
  IEditorFields,
  IReviewerFields,
} from '../../../declarations/contentful';
import { getSocialLink } from '../../../helpers/socialLinks';
import { IArticlesLoaderReturn } from '../../shared/Articles';

type IExpert = IAuthorFields | IEditorFields | IReviewerFields;

const getExpertsFromContext = (context: IRenderContext) => {
  const isRelatedArticle = (obj: any): obj is IComponentRelatedArticles => {
    return obj?.sys?.contentType?.sys?.id === 'componentRelatedArticles';
  };

  const expertsList = context.body.content.reduce<IExpert[]>((acc, content) => {
    if (!isRelatedArticle(content.data.target)) {
      return acc;
    }
    const loaderData = context.idToLoaderData[content.data.target.sys.id] as IArticlesLoaderReturn;
    (loaderData.experts ?? []).forEach((expert) => {
      expert && acc.push(expert);
    });
    return acc;
  }, []);

  // Deduplicate experts
  const expertsMap: { [name: string]: IExpert } = {};
  expertsList.forEach((expert) => (expertsMap[expert.displayName] = expert));
  return Object.values(expertsMap);
};

const SocialLink: React.FC<{ url: string; children: ReactNode }> = ({ url, children }) => (
  <Link
    aria-label=""
    href={url}
    target="_blank"
    analyticsName="cosmos-topicpage-experts-sociallink"
    display="flex"
    alignItems="center"
    opacity="0.2"
    _hover={{ opacity: '1.0' }}
  >
    {children}
  </Link>
);

const Bio: React.FC<{ bio?: string }> = ({ bio }) => {
  const [isBioExpanded, setBioExpanded] = useState<boolean>(false);
  const partialBio = bio?.split(' ').slice(0, 30).join(' ');
  return (
    <Text tag="p" typography="$bodyMedium" display="block">
      {isBioExpanded ? bio : partialBio}{' '}
      <Link
        onClick={() => setBioExpanded(true)}
        analyticsName="cosmos-topicpage-experts-bio"
        aria-label=""
        display={isBioExpanded || partialBio === bio ? 'none' : 'inline'}
      >
        Read More
      </Link>
    </Text>
  );
};

const renderExpert = (
  entry: IComponentExperts,
  _resolvedData?: any, // unused argument
  context?: IRenderContext,
) => {
  if (!context) {
    return null;
  }
  let expertsList: IExpert[] = (entry.fields?.items ?? []).map((item) => item.fields);
  if (expertsList.length == 0) {
    expertsList = getExpertsFromContext(context);
  }
  expertsList = expertsList.slice(0, entry.fields?.limit ?? expertsList.length);
  return (
    <Flex
      flexDirection={['column', 'row']}
      alignItems="start"
      flexWrap="wrap"
      gridColumnGap={4}
      gridRowGap={8}
      width={['100%', null, null, '100%']}
      mt="8"
    >
      {expertsList.map((expert, index) => (
        <Flex
          flexDirection="column"
          key={index}
          width={['100%', '280px']}
          alignItems={['start', 'center']}
        >
          <Avatar
            size="lg"
            src={expert.picture}
            initials={(expert.firstName ?? '').charAt(0) + (expert.lastName ?? '').charAt(0)}
          />
          <Text marginVertical="$4x" tag="p" typography="$subheaderXsmall">
            {expert.displayName}
          </Text>
          <Bio bio={expert.bio} />
          <Flex width="100%" ml="2" mt="3" gridGap="4">
            {expert.instagramUsername && (
              <SocialLink url={getSocialLink('instagram', expert.instagramUsername)}>
                <SocialIcon socialPlatform="instagram" />
              </SocialLink>
            )}
            {expert.linkedInUsername && (
              <SocialLink url={getSocialLink('linkedin', expert.linkedInUsername)}>
                <SocialIcon socialPlatform="linkedin" />
              </SocialLink>
            )}
            {expert.twitterUsername && (
              <SocialLink url={getSocialLink('twitter', expert.twitterUsername)}>
                <SocialIcon socialPlatform="twitter" />
              </SocialLink>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

const Experts: EntryComponent<IComponentExperts> = {
  render: renderExpert,
};

export default Experts;
