import EmailInputComponent from '../../components/articles-v2/EmailInput';
import ImageAsset from '../../components/articles-v2/ImageAsset';
import EmailHero from '../../components/articles-v2/topicPage/EmailHero';
import Experts from '../../components/articles-v2/topicPage/Experts';
import Hero from '../../components/articles-v2/topicPage/Hero';
import ImageAssetLink from '../../components/articles-v2/topicPage/ImageAssetLink';
import InfoCard from '../../components/articles-v2/topicPage/InfoCard';
import MediaAssetSection from '../../components/articles-v2/topicPage/MediaAssetSection';
import NavBar from '../../components/articles-v2/topicPage/NavBar';
import TopTopics from '../../components/articles-v2/topicPage/TopTopics';
import RelatedArticles from '../../components/shared/Articles';
import IEntryLookup from './entries';

const topicPageEntryLookup: IEntryLookup = {
  block: {
    componentRelatedArticles: RelatedArticles,
    ctaEmailCapture: EmailInputComponent,
    componentTopTopics: TopTopics,
    componentExperts: Experts,
    componentEmailHero: EmailHero,
    componentHeroV2: Hero,
    componentMediaAssetSection: MediaAssetSection,
    componentImageAssetLink: ImageAssetLink,
    componentInfoCard: InfoCard,
    componentNavBar: NavBar,
    imageAsset: ImageAsset,
  },
  inline: {},
};

export default topicPageEntryLookup;
