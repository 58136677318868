import React, { useEffect, useState } from 'react';

import { Box, Button, Flex, Icon } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronLeft';
import ChevronRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight';
import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import { Breakpoints, colors } from '@opendoor/bricks/theme/eero';

// At different breakpoints, we show a different number of cards per slide
const responsiveCarousel: { [key in Breakpoints]: number } = {
  XS: 1,
  SM: 1,
  MD: 2,
  LG: 3,
  XL: 3,
};

export const CarouselItem: React.FC<{
  children: React.ReactNode;
  numCardsVisible?: number | undefined;
}> = ({ children, numCardsVisible }) => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      px={[4, 6]}
      minWidth={[
        `${100 / (numCardsVisible ?? responsiveCarousel.XS)}%`,
        `${100 / (numCardsVisible ?? responsiveCarousel.SM)}%`,
        `${100 / (numCardsVisible ?? responsiveCarousel.MD)}%`,
        `${100 / (numCardsVisible ?? responsiveCarousel.LG)}%`,
        `${100 / (numCardsVisible ?? responsiveCarousel.XL)}%`,
      ]}
    >
      {children}
    </Box>
  );
};

// Determines length of carousel depending on screen size
function useCarouselLength(totalItems: number, numCardsVisible: number | undefined) {
  const { isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop } = useDevice();
  const [carouselLength, setCarouselLength] = useState(totalItems);
  const [numInView, setNumInView] = useState(1);

  useEffect(() => {
    let base = 1;
    if (isSmallMobile) {
      base = responsiveCarousel.XS;
    } else if (isMobile) {
      base = responsiveCarousel.SM;
    } else if (isTablet) {
      base = responsiveCarousel.MD;
    } else if (isDesktop) {
      base = responsiveCarousel.LG;
    } else if (isLargeDesktop) {
      base = responsiveCarousel.XL;
    }

    if (numCardsVisible) {
      base = numCardsVisible;
    }

    setCarouselLength(Math.round(totalItems / base));
    setNumInView(base);
  }, [isSmallMobile, isMobile, isTablet, isDesktop, isLargeDesktop]);

  return { numInView, carouselLength };
}

function useCarousel(
  // Total number of items in carousel
  totalItems: number,
  // Miliseconds bewteen auto rotation
  duration: number | undefined,
  numCardsVisible: number | undefined,
) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const { carouselLength, numInView } = useCarouselLength(totalItems, numCardsVisible);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = carouselLength - 1;
    } else if (newIndex >= carouselLength) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const goToPrevious = () => {
    updateIndex(activeIndex - 1);
  };

  const goToNext = () => {
    updateIndex(activeIndex + 1);
  };

  useEffect(() => {
    // If duration is passed in, create a timer to set up auto-rotation behavior
    if (duration) {
      const interval = setInterval(() => {
        if (!paused) {
          updateIndex(activeIndex + 1);
        }
      }, duration);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
    return;
  });

  // Returns carousel state and handler functions
  return {
    activeIndex,
    updateIndex,
    goToNext,
    goToPrevious,
    setPaused,
    carouselLength,
    numInView,
  };
}

interface CardCarouselProps {
  children: React.ReactNode;
  width?: string | Array<string>;
  height?: string | Array<string>;
  autoRotate?: boolean;
  showIndicators?: boolean;
  numCardsVisible?: number | undefined;
}

const CardCarousel: React.FC<CardCarouselProps> = ({
  children,
  width = '100%',
  height = '400px',
  autoRotate = false,
  showIndicators = false,
  numCardsVisible,
}) => {
  const totalItems = React.Children.count(children);
  const { activeIndex, updateIndex, goToNext, goToPrevious, setPaused, numInView, carouselLength } =
    useCarousel(totalItems, autoRotate ? 3000 : undefined, numCardsVisible);

  return (
    <>
      <SelectiveSpritesheet icons={[ChevronRight, ChevronLeft]} />
      <Flex
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
        position="relative"
        overflow="hidden"
        height={height}
        width={width}
      >
        <Flex
          height={height}
          width="100%"
          position="absolute"
          transition="transform 0.3s"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child) => {
            return child;
          })}
        </Flex>

        {totalItems > numInView && (
          <>
            {/* TODO:  Update the below buttons to bricks-next once icon buttons are ready */}
            <Button
              variant="icon"
              position="absolute"
              left="0"
              top="50%"
              transform="transformY(-50%)"
              analyticsName="cosmos-card-carousel-previous"
              aria-label="Go to previous carousel item"
              onClick={goToPrevious}
            >
              <Icon name="chevron-left" color="neutrals90" size={24} />
            </Button>
            <Button
              variant="icon"
              position="absolute"
              top="50%"
              transform="transformY(-50%)"
              right="0"
              analyticsName="cosmos-card-carousel-next"
              aria-label="Go to next carousel item"
              onClick={goToNext}
            >
              <Icon name="chevron-right" color="neutrals90" size={24} />
            </Button>
          </>
        )}
      </Flex>
      {showIndicators && carouselLength > 1 && (
        <Flex gap="4" px={[6, 8]} py={[6, 8]} flexWrap="wrap" justifyContent="center">
          {Array(carouselLength)
            .fill(0)
            .map((_child, index) => {
              return (
                <Button
                  key={`card-carousel-${index}`}
                  variant="ghost"
                  analyticsName="cosmos-card-carousel-indicator"
                  aria-label="Go to numbered carousel item"
                  onClick={() => {
                    updateIndex(index);
                  }}
                >
                  <Box
                    height="8px"
                    width="8px"
                    borderRadius="rounded"
                    backgroundColor={index === activeIndex ? 'brand50' : 'neutrals40'}
                    _hover={{
                      backgroundColor: colors.brand30,
                    }}
                  ></Box>
                </Button>
              );
            })}
        </Flex>
      )}
    </>
  );
};

export default CardCarousel;
