import { Link } from '@opendoor/bricks/core';
import { globalObservability } from '@opendoor/observability/slim';

import { fetchEntryById } from 'cms/api';

import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import {
  IComponentImageAssetLink,
  IImageAsset,
  IImageAssetFields,
} from '../../../declarations/contentful';
import ImageAsset from '../ImageAsset';

const renderImageLink = (
  entry: IComponentImageAssetLink,
  resolvedData?: Awaited<ReturnType<typeof imageAssetLinkLoader>>,
) => {
  const imageAsset = entry?.fields?.image || resolvedData?.hydratedImageAsset;

  return (
    <Link
      href={entry.fields.uri}
      target="_blank"
      analyticsName="cosmos-articles-image"
      aria-label=""
      pr={8}
    >
      {imageAsset && ImageAsset.render(imageAsset)}
    </Link>
  );
};

interface ImageAssetLinkLoaderReturn {
  hydratedImageAsset?: IImageAsset;
}

const imageAssetLinkLoader = async (input: IComponentImageAssetLink) => {
  const loaderReturn: ImageAssetLinkLoaderReturn = {};
  try {
    const imageAsset = await fetchEntryById<IImageAssetFields>(
      'imageAsset',
      input?.fields?.image?.sys?.id,
      {
        include: 2,
      },
    );
    loaderReturn.hydratedImageAsset = imageAsset as IImageAsset;
    return loaderReturn;
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        input,
      },
    });
  }
  return loaderReturn;
};

const ImageAssetLink: EntryComponent<
  IComponentImageAssetLink,
  Awaited<ReturnType<typeof imageAssetLinkLoader>>
> = {
  render: renderImageLink,
  loader: imageAssetLinkLoader,
};

export default ImageAssetLink;
