import React, { Fragment, MouseEvent } from 'react';

import { Box } from '@opendoor/bricks-next';
import { Flex, Image, Link, Text } from '@opendoor/bricks/core';
import { shadows } from '@opendoor/bricks/theme/eero';
import { Asset, Entry } from 'contentful';

import { createContentfulClient } from 'cms/api';

import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import renderImage from '../../../cms/renderer/assets';
import CardCarousel, { CarouselItem } from '../../../components/shared/CardCarousel';
import { IComponentTopTopics, ITopicPageFields } from '../../../declarations/contentful';
import { COSMOS_URL } from '../../globals';
import typography from '../typography';

type ITopTopic = Pick<ITopicPageFields, 'topicName' | 'slug' | 'topicThumbnail'>;

const TopTopicsCard: React.FC<{
  topicName: string;
  slug: string;
  topicThumbnail?: Asset | undefined;
  cardIndex?: number;
}> = ({ topicName, slug, topicThumbnail, cardIndex }) => {
  const photoUrl = topicThumbnail?.fields?.file?.url;
  return (
    <Flex
      width="100%"
      cursor="pointer"
      flexDirection="column"
      p={0}
      css={{ boxShadow: shadows.small }}
      role="group"
      borderRadius="semiRounded"
      overflow="hidden"
      height="95%"
    >
      <Link
        aria-label="Go to top topic page"
        href={`${COSMOS_URL}${slug}`}
        analyticsName="cosmos-article-top-topics-card"
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
        display="flex"
        alignItems="flex-end"
        p={[5, 5, 7]}
        height="100%"
        position="relative"
      >
        <Text
          zIndex="0"
          as="h1"
          lineHeight="120"
          color="neutrals0"
          fontSize={['s2', null, 's3', 's3']}
          fontWeight="semibold"
        >
          {topicName}
        </Text>
        <Box position="absolute" zIndex={-1} top={0} left={0} width="100%">
          {photoUrl ? (
            renderImage({
              src: photoUrl,
              description: topicThumbnail?.fields?.description,
              width: topicThumbnail?.fields?.file?.details?.image?.width || 200,
              height: topicThumbnail?.fields?.file?.details?.image?.height || 300,
            })
          ) : (
            <Image
              alt="Entrance to home"
              src={
                cardIndex && cardIndex % 2 === 0
                  ? 'https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1680888195610-48794.png?preset=square-2048'
                  : 'https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1680888329782-61343.png?preset=square-2048'
              }
              objectPosition="left"
            />
          )}{' '}
        </Box>
      </Link>
    </Flex>
  );
};

const renderTopTopics = (
  entry: IComponentTopTopics,
  resolvedData?: Awaited<ReturnType<typeof topTopicsLoader>>,
) => {
  // Replace self-reference item to root
  if (entry?.fields?.items) {
    const selfIndex = entry.fields.items.findIndex((item) => !item.fields);
    if (selfIndex !== -1 && resolvedData?.topicName && resolvedData?.slug) {
      entry.fields.items[selfIndex].fields = {
        topicName: resolvedData?.topicName,
        slug: resolvedData?.slug,
      } as ITopicPageFields;
    }
  }

  let topics: ITopTopic[] =
    entry?.fields?.items
      ?.filter((item) => !!item?.fields?.slug)
      ?.map((item) => ({
        topicName: item.fields.topicName,
        slug: item.fields.slug,
        topicThumbnail: item.fields.topicThumbnail,
      })) ??
    resolvedData?.topics ??
    [];
  topics = topics.slice(0, entry.fields?.limit ?? topics.length);

  return (
    <Box my="$6x" $largerThanXS={{ my: '$10x' }}>
      {entry?.fields?.title && (
        <Text {...typography.h3} mb={4}>
          {entry?.fields?.title}
        </Text>
      )}
      {entry?.fields?.subtitle && <Text mb={4}>{entry?.fields?.subtitle}</Text>}
      <CardCarousel height={['320px', '320px', '380px']} showIndicators>
        {topics.map(({ topicName, slug, topicThumbnail }, index) => (
          <CarouselItem key={`carousel-item-${index}-${slug}`}>
            <Fragment key={index}>
              <TopTopicsCard
                topicName={topicName || ''}
                slug={slug}
                cardIndex={index}
                topicThumbnail={topicThumbnail}
              />
            </Fragment>
          </CarouselItem>
        ))}
      </CardCarousel>
    </Box>
  );
};

const topTopicsLoader = async (input: IComponentTopTopics, root?: Entry<any>) => {
  if ((input.fields?.items ?? []).length > 0) {
    return {
      topics: [],
      topicName: root?.fields.topicName ?? null,
      slug: root?.fields.slug ?? null,
    };
  }

  const client = createContentfulClient();
  const response = await client.getEntries<ITopicPageFields>({
    content_type: 'topicPage',
    order: 'sys.createdAt',
    'fields.slug[match]': '/articles/topic/',
    select: 'fields.slug,fields.topicName',
    limit: input.fields?.limit,
  });
  return {
    topics: response.items.map((item) => item.fields) as ITopTopic[],
    topicName: root?.fields.topicName ?? null,
    slug: root?.fields.slug ?? null,
  };
};

const TopTopics: EntryComponent<
  IComponentTopTopics,
  Awaited<ReturnType<typeof topTopicsLoader>>
> = {
  render: renderTopTopics,
  loader: topTopicsLoader,
};

export default TopTopics;
