/* storybook-check-ignore */
import {
  H1 as BricksH1,
  H2 as BricksH2,
  H3 as BricksH3,
  H4 as BricksH4,
} from '@opendoor/bricks/core';

export const H1: React.FC<React.ComponentProps<typeof BricksH1>> = (props) => (
  <BricksH1
    fontSize={['UNSAFE_h2', null, 'UNSAFE_h1Mobile', 's5']}
    fontWeight="bolder"
    lineHeight={['120', null, '140']}
    {...props}
  />
);

export const H2: React.FC<React.ComponentProps<typeof BricksH2>> = (props) => (
  <BricksH2
    fontSize={['UNSAFE_h2', null, 'UNSAFE_h1Mobile']}
    fontWeight="bolder"
    lineHeight={['120', null, '140']}
    {...props}
  />
);

export const H3: React.FC<React.ComponentProps<typeof BricksH3>> = (props) => (
  <BricksH3
    fontSize={['s2', null, 's3']}
    fontWeight="bolder"
    lineHeight={['120', null, '140']}
    {...props}
  />
);

export const H4: React.FC<React.ComponentProps<typeof BricksH4>> = (props) => (
  <BricksH4
    fontSize={['s00', null, 's0']}
    fontWeight="bolder"
    lineHeight={['120', null, '140']}
    {...props}
  />
);
