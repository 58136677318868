/* storybook-check-ignore */
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  Text as ContentfulText,
  Document,
  Inline,
  INLINES,
  Paragraph,
} from '@contentful/rich-text-types';
import { Text } from '@opendoor/bricks-next';
import { Flex, H1, H2, H3, H4, Icon, Link } from '@opendoor/bricks/core';

import { EntryComponent } from '../../../cms/entries/entries';
import typography from '../../../components/articles-v2/typography';
import { COSMOS_URL } from '../../../components/globals';
import { IComponentHeroV2 as IComponentHero } from '../../../declarations/contentful';
import { SellerFlowAddressInput } from '../../shared/SellerFlowAddressInput';

const richTextRenderer = (richTextDocument: Document, color = '#000') => {
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node: Block | Inline, children: React.ReactNode) => {
        return (
          <H1
            {...typography.h1}
            mt={[3, 3, 4]}
            mb={[2, 2, 3]}
            style={{
              color,
            }}
          >
            {children}
          </H1>
        );
      },
      [BLOCKS.HEADING_2]: (_node: Block | Inline, children: React.ReactNode) => {
        return (
          <H2
            mt={[3, 3, 4]}
            mb={[2, 2, 3]}
            {...typography.h2}
            style={{
              color,
            }}
          >
            {children}
          </H2>
        );
      },
      [BLOCKS.HEADING_3]: (_node: Block | Inline, children: React.ReactNode) => {
        return (
          <H3
            mt={3}
            mb={2}
            {...typography.h3}
            style={{
              color,
            }}
          >
            {children}
          </H3>
        );
      },
      [BLOCKS.HEADING_4]: (_node: Block | Inline, children: React.ReactNode) => {
        return (
          <H4
            style={{
              color,
            }}
            mt={[2, 2, 3]}
            mb={[1, 1, 2]}
            {...typography.h4}
          >
            {children}
          </H4>
        );
      },
      [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode) => {
        let target = '_blank';
        if (node.data.uri.startsWith('#') || node.data.uri.startsWith(COSMOS_URL)) {
          target = '_self';
        }
        return (
          <Link
            href={node.data.uri}
            analyticsName="cosmos-articles-hero-cta"
            aria-label=""
            target={target}
            lineHeight="s1"
            style={{
              color,
            }}
            fontWeight="semibold"
            fontSize={['s1', 's1', 's2']}
            mb={0}
            _hover={{
              color: 'neutrals0',
            }}
            _active={{
              color: 'neutrals0',
            }}
            display="flex"
            alignItems="center"
          >
            {children}
            <Icon
              ml="3"
              name="chevron-right"
              style={{
                color,
              }}
              size={16}
              position="relative"
            />
          </Link>
        );
      },
      [BLOCKS.PARAGRAPH]: (entry: Block | Inline, children) => {
        const paragraphEntry = entry as Paragraph;
        if (!Array.isArray(children)) {
          return (
            <Text tag="p" typography="$bodyMedium">
              {children}
            </Text>
          );
        }
        const zipped: Array<[Inline | ContentfulText, React.ReactNode]> =
          paragraphEntry.content.map((e, i) => [e, children[i]]);
        const { inline, standard } = zipped.reduce<{
          inline: Array<React.ReactNode>;
          standard: Array<React.ReactNode>;
        }>(
          (acc, curr) => {
            const [entry, child] = curr;
            if (entry.nodeType === INLINES.EMBEDDED_ENTRY) {
              acc.inline = acc.inline.concat(child);
              return acc;
            }
            acc.standard = acc.standard.concat(child);
            return acc;
          },
          { inline: [], standard: [] },
        );
        // note: this re-orders the text components, i.e. if you have
        // test <inline entry> test2, it will be rendered as
        // <inline entry> test test2. This doesn't seem ideal, but works
        // for our use-case. We may want to disallow inline embedded entries
        // in the future, and just use the block type.
        return (
          <>
            {inline}
            <Text tag="p" typography="$bodyMedium">
              {standard}
            </Text>
          </>
        );
      },
    },
  };
  return documentToReactComponents(richTextDocument, options);
};

const HeroComponent = (entry: IComponentHero) => {
  const { fields } = entry;
  const color = entry?.fields?.textColor;
  const title = fields?.title ? richTextRenderer(fields?.title, color) : null;
  const subtitle = fields?.subtitle ? richTextRenderer(fields?.subtitle, color) : null;
  const desktopHeroImage = entry?.fields?.desktopImage?.fields?.file?.url;
  const mobileHeroImage = entry?.fields?.mobileImage?.fields?.file?.url;

  return (
    <Flex
      as="section"
      id="hero-container"
      backgroundImage={[`url(${mobileHeroImage})`, null, `url(${desktopHeroImage})`]}
      backgroundPosition="bottom center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      width="100%"
      minHeight="480px"
      my={[6, 6, 9, 9]}
      px={[8, 8, 10]}
      py={[8, 8, 12]}
      gap="6"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
      textAlign="left"
    >
      {title && (
        <Flex flexDir="column" width="100%">
          {title}
        </Flex>
      )}
      {subtitle && <Flex width="100%">{subtitle}</Flex>}
      {fields?.showOnboardingCta && (
        <Flex mt={4}>
          <SellerFlowAddressInput
            analyticsName="cosmos-articles-topic-page-address-input"
            channel="DEFAULT"
            inputLocation="offer-cta"
            autoFocus={false}
            placeholderText="Enter your address"
            showCta
            ctaProps={{
              actionText: 'Get your free offer',
              'aria-label': '',
            }}
            hideLabel
            product="SELL_DIRECT"
          />
        </Flex>
      )}
    </Flex>
  );
};

const Hero: EntryComponent<IComponentHero> = {
  render: HeroComponent,
};

export default Hero;
