/* storybook-check-ignore */
import { Box } from '@opendoor/bricks-next';
import { Image, Text } from '@opendoor/bricks/core';
import { globalObservability } from '@opendoor/observability/slim';

import { fetchEntriesById } from 'cms/api';

import { Awaited, EntryComponent, IRenderContext } from '../../../cms/entries/entries';
import topicPageEntryLookup from '../../../cms/entries/topicPage-v2';
import { IIdToLoaderData } from '../../../cms/loaders';
import RichTextRenderer from '../../../cms/renderer';
import {
  IComponentMediaAsset,
  IComponentMediaAssetSection,
} from '../../../declarations/contentful';

export type MediaAssetSectionProps = {
  entry: IComponentMediaAssetSection;
};

const renderMediaAssetSection = (
  entry: IComponentMediaAssetSection,
  resolvedData?: Awaited<ReturnType<typeof mediaAssetSectionLoader>>,
  context?: IRenderContext,
) => {
  /*
   * Prefer resolvedData over entry because mediaAssetSectionLoader below fetches
   * the nested fields for each componentMediaAsset.
   */
  const mediaAssets = resolvedData?.mediaAssets || entry?.fields?.mediaAssets;
  if (mediaAssets?.length === 0) {
    return null;
  }
  const idToDataLoader = context?.idToLoaderData;
  return (
    <Box
      width="100%"
      flexDirection="row"
      flexWrap="wrap"
      paddingVertical="$4x"
      $largerThanSM={{ paddingVertical: '$8x' }}
    >
      {mediaAssets?.map((item, index) => {
        const {
          fields: { title, subtitle, logo, content },
        } = item;
        return (
          <Box
            width="100%"
            $largerThanXS={{ width: '50%' }}
            $largerThanSM={{ width: '33%' }}
            justifyContent="space-between"
            key={`media-asset-${Math.random()}-${index}`}
            rowGap="$10x"
          >
            <Box>
              {logo?.fields?.asset?.fields?.file?.url && (
                <Box
                  width={50}
                  height={50}
                  paddingBottom="$2x"
                  $largerThanSM={{ paddingBottom: '$4x' }}
                >
                  <Image
                    position="absolute"
                    src={logo.fields.asset.fields.file.url}
                    alt={logo?.fields.asset.fields.description}
                    objectFit="cover"
                  />
                </Box>
              )}
              {title && (
                <Text fontWeight="semibold" fontSize="s2" mb={3}>
                  {title}
                </Text>
              )}
              {subtitle && <Text color="neutrals90">{subtitle}</Text>}
            </Box>
            {content && (
              <Box paddingTop="$4x" flexDirection="row">
                {
                  <RichTextRenderer.Component
                    body={content}
                    idToLoaderData={idToDataLoader as IIdToLoaderData}
                    entryLookup={topicPageEntryLookup}
                  />
                }
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface ImageAssetLinkLoaderReturn {
  mediaAssets: Array<IComponentMediaAsset>;
}

const mediaAssetSectionLoader = async (input: IComponentMediaAssetSection) => {
  const loaderReturn: ImageAssetLinkLoaderReturn = {
    mediaAssets: [],
  };
  const entryIds = input?.fields?.mediaAssets?.map((item) => item?.sys?.id);
  if (entryIds?.length === 0 || entryIds === undefined) {
    return loaderReturn;
  }
  try {
    const result = await fetchEntriesById<IComponentMediaAsset>('componentMediaAsset', entryIds, {
      include: 2,
    });
    loaderReturn.mediaAssets = result?.map((entry) => entry ?? null) as Array<IComponentMediaAsset>;
    return loaderReturn;
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        input,
      },
    });
  }
  return loaderReturn;
};

const MediaAssetSection: EntryComponent<
  IComponentMediaAssetSection,
  Awaited<ReturnType<typeof mediaAssetSectionLoader>>
> = {
  render: renderMediaAssetSection,
  loader: mediaAssetSectionLoader,
};

export default MediaAssetSection;
