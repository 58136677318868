import React from 'react';

import styled from '@emotion/styled';
import AutocompleteInput, {
  IAutocompleteInputProps,
  IOptionsDisplayProps,
  ResultList,
} from '@opendoor/bricks/complex/AutocompleteInput/AutocompleteInput';
import { IAutocompleteOption } from '@opendoor/bricks/complex/AutocompleteInput/AutocompleteOption';
import { CTAProps, IWrapperProps } from '@opendoor/bricks/complex/CtaInput/shared';
import { Box, Button, ButtonProps } from '@opendoor/bricks/core';
import { colors, space } from '@opendoor/bricks/theme/eero';
import { globalObservability } from '@opendoor/observability/slim';
import debounce from 'lodash/debounce';

import { OdProtosSellReceptionData_SellerInput_Channel } from '__generated__/athena';

// Generate unique session token for API billing purposes
// https://developers.google.com/maps/documentation/places/web-service/session-tokens
const generateGoogleMapsSessionToken = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
};

const ADDRESS_INPUT_URL = '/_address-input';

export interface IAddressInputAddress {
  street1: string;
  city: string;
  state: string;
  postal_code: string;
  unit?: string;
  latitude?: number;
  longitude?: number;
}

export interface IPostAddressOptions {
  manual?: boolean;
  agent_request?: boolean;
  seller_flow_uuid_override?: string;
  channel?: string;
  trackingKeywords?: string;
}

const AddressInputDiv = styled(Box)`
  display: block;
  position: relative;

  & .invalid-input,
  & .autocomplete--error {
    border: none;
    box-shadow: none;
  }

  & ${ResultList}::after {
    content: 'powered by Google';
    display: block;
    width: 98%;
    color: transparent;
    background: url('https://images.opendoor.com/source/s3/imgdrop-production/powered-by-google.png?preset=square-2048&h=18')
      no-repeat center;
    background-size: 104px 16px;
    font-size: 14px;
  }
`;

const InlineError = styled(ResultList)`
  background-color: ${colors.red50};
  color: #fff;
  padding: ${space[2]}px ${space[6]}px;
  border: none;
  text-align: center;
  margin-top: -1px;
`;

const InlineErrorLink: React.FC<ButtonProps> = (props) => (
  <Button
    variant="inline-link"
    color="neutrals0"
    _hover={{ color: 'neutrals30' }}
    _active={{ color: 'neutrals30' }}
    backgroundColor="transparent"
    {...props}
  />
);

const ALLOWED_TYPES = ['street_address', 'premise', 'subpremise', 'route'];

type BaseAddressInputProps = IWrapperProps &
  Pick<
    IAutocompleteInputProps,
    'onFocus' | 'onBlur' | 'autoFocus' | 'className' | 'ariaLabelledby'
  > & {
    /**
     * Name of the address input in analytics events, should be unique within the app
     * Convention: {appName}-{addressInputName}
     */
    analyticsName: string;
    autocompleteService?: google.maps.places.AutocompleteService;
    channel?: OdProtosSellReceptionData_SellerInput_Channel;
    hideErrorFormLink?: boolean;
    initialInput?: string;
    onChooseManualEntry?: (channel?: string) => void;
    onQueryChange?: (newQuery: string, oldQuery: string) => void;
    onValidate?: (invalidUserInput: boolean) => void;
    onSubmit?: (address: IAddressInputAddress, channel?: string) => void;
    // called when the component is ready, useful for analytics
    onInputReady?: () => void;
    optionsDisplayProps?: IOptionsDisplayProps;
    paddingLevel?: 'none' | 'light' | 'medium';
    placeholderText?: string;
    'aria-label'?: string;
    placesService?: google.maps.places.PlacesService;
    renderError?: () => void;
    setValueOnBlur?: boolean;
    showError?: boolean;
    shouldRedirectOnSubmit?: boolean;
    showCta?: boolean;
  };

type AddresInputProps = BaseAddressInputProps & { showCta: false };
type AddressInputWithCtaProps = BaseAddressInputProps & {
  showCta: true;
  analyticsName?: string;
  ctaProps: CTAProps;
};

export type IProps = AddresInputProps | AddressInputWithCtaProps;

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

type AddressOption = IAutocompleteOption & { prediction: AutocompletePrediction };

type AddressInputState = {
  addressError: boolean;
  hasLoaded: boolean;
  autocompleteOptions: Array<AutocompletePrediction>;
  fetchIsLoading: boolean;
  addressLoading: boolean;
  submitOnLoadingComplete: boolean;
  query: string;
  selectedIndex: number;
  sessionToken: string;
};

/* storybook-check-ignore */
export default class AddressInput extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    setValueOnBlur: false,
    hideErrorFormLink: false,
    onChooseManualEntry: () => {},
    onQueryChange: () => {},
    onSubmit: () => {},
    onInputReady: () => {},
    onFocus: () => {},
    onBlur: () => {},
    paddingLevel: 'medium',
    placeholderText: 'Enter your home address',
    showBorder: true,
    showShadow: false,
    showCta: true,
    ctaProps: {
      actionText: 'Get offer',
      variant: 'primary',
      'aria-label': '',
    },
    unboundedWidth: true,
    showError: true,
    shouldRedirectOnSubmit: true,
  };

  public state: AddressInputState = {
    addressError: false,
    hasLoaded: false,
    autocompleteOptions: [] as Array<AutocompletePrediction>,
    fetchIsLoading: false,
    addressLoading: false,
    submitOnLoadingComplete: false,
    query: this.props.initialInput || '',
    selectedIndex: -1,
    sessionToken: generateGoogleMapsSessionToken(),
  };

  private fetchAutocompleteOptions = debounce((input: string) => {
    this.setState({ addressError: false, fetchIsLoading: true });
    if (!input) {
      this.setState({
        addressError: false,
        autocompleteOptions: [],
        selectedIndex: -1,
      });
      return;
    }

    this.setState({ addressError: false });
    fetch(this.buildAutocompleteUrl(input))
      .then((res) => res.json())
      .then((res) => {
        res.predictions.forEach((result: AutocompletePrediction) => {
          result.description = result.description.replace(', United States', '');
        });

        const autocompleteOptions = res.predictions.filter((result: AutocompletePrediction) => {
          const resultAllowedTypes = result.types.filter((value) => ALLOWED_TYPES.includes(value));
          return (
            (result.terms.length >= 5 && resultAllowedTypes.length != 0) ||
            (result.terms.length === 4 && result.terms[0].value.match(/^\d+\S*\s\S+\s/) !== null)
          );
        });
        this.setState({ autocompleteOptions, fetchIsLoading: false, hasLoaded: true });
      })
      .catch(() => this.setState({ addressError: true, fetchIsLoading: false }));
  }, 100);

  public handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!this.state.query.trim()) {
      return;
    }
    if (!this.state.addressLoading) {
      this.chooseFirstOrSelectedItem();
    }
  };

  public handleBlur: IAutocompleteInputProps['onBlur'] = (
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (this.props.setValueOnBlur && this.state.selectedIndex < 0) {
      this.chooseFirstOrSelectedItem();
    }
    this.props.onBlur && this.props.onBlur(e);
  };

  public handleQueryChange = (newValue: string, fromResultsSelection: boolean) => {
    if (!fromResultsSelection) {
      this.props.onQueryChange && this.props.onQueryChange(newValue, this.state.query);
      // use handleQueryChange for fetching results instead of updateOptions since
      // updateOptions has a default debounce of 500, and we want to be quicker
      // in this case
      this.fetchAutocompleteOptions(newValue);
      this.setState({ query: newValue, hasLoaded: false });
    } else {
      // user has used the arrow keys or mouse to select a value in our results dropdown
      this.setState({ query: newValue });
    }
  };

  // Populate the initial autocomplete options. These aren't shown, but they are
  // required in order for the AutocompleteInput to work
  public componentDidMount() {
    if (this.state.query !== '') {
      this.fetchAutocompleteOptions(this.state.query);
    }
  }

  public componentDidUpdate(_prevProps: IProps, prevState: AddressInputState) {
    if (
      this.state.submitOnLoadingComplete &&
      this.state.fetchIsLoading == false &&
      prevState.fetchIsLoading == true
    ) {
      this.chooseFirstOrSelectedItem();
      this.setState({ submitOnLoadingComplete: false });
    }
  }

  public render() {
    const {
      ariaLabelledby,
      autoFocus,
      className,
      paddingLevel,
      placeholderText,
      optionsDisplayProps,
      unboundedWidth,
      designStyle,
      showCta,
      showBorder,
      showShadow,
      showError,
      size,
      analyticsName,
      'aria-label': ariaLabel,
    } = this.props;

    const { query, addressLoading, autocompleteOptions, fetchIsLoading, addressError } = this.state;

    return (
      <AddressInputDiv className={'od-address-input ' + (className || '')}>
        <AutocompleteInput
          autoComplete="off"
          ariaLabelledby={ariaLabelledby}
          ariaLabel={ariaLabel}
          analyticsName={analyticsName}
          name="AddressInput"
          input={query || ''}
          showCta={showCta}
          {...(showCta && 'ctaProps' in this.props
            ? {
                ctaProps: {
                  ...this.props.ctaProps,
                  actionText: this.props.ctaProps?.actionText ?? 'Get offer',
                  variant: this.props.ctaProps?.variant ?? 'primary',
                  onClick: this.handleClick,
                  loading: addressLoading,
                  disabled: addressLoading,
                  size: size,
                },
              }
            : {})}
          inputProps={{
            id: 'address-input',
            autoFocus,
            paddingLevel,
            unboundedWidth,
            designStyle,
            showBorder,
            showShadow,
          }}
          optionsDisplayProps={optionsDisplayProps}
          placeholderText={placeholderText}
          onInputChange={this.handleQueryChange}
          select={this.handleItemClick as any}
          onFocus={this.onInputFocus}
          onBlur={this.handleBlur}
          options={autocompleteOptions.map(this.placeToOption)}
          failure={addressError}
          renderError={showError ? this.displayManualEntry : undefined}
          fetchOptionsStatus={{ isLoading: fetchIsLoading }}
        />
      </AddressInputDiv>
    );
  }

  public onInputFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    this.props.onFocus && this.props.onFocus(e);
  };

  public handleItemClick = (autocompleteItem: AddressOption) => {
    if (!autocompleteItem.value) {
      // if autocompleteItem does not a value, then it is the raw
      // text typed in from the user
      this.chooseFirstOrSelectedItem();
    } else {
      this.chooseResult(autocompleteItem);
    }
  };

  private buildAutocompleteUrl = (query: string) => {
    return `${ADDRESS_INPUT_URL}/autocomplete?sessionToken=${this.state.sessionToken}&query=${query}`;
  };

  private buildPlacesUrl = (placeId: string) => {
    return `${ADDRESS_INPUT_URL}/place?sessionToken=${this.state.sessionToken}&placeId=${placeId}`;
  };

  private chooseResult = (result?: AddressOption) => {
    if (!result || !result.prediction) {
      if (this.state.fetchIsLoading || !this.state.hasLoaded) {
        // If we've not loaded any results since last query change and we are
        // loading still, then wait until loading has completed before deciding
        // where to send the user
        if (!this.state.submitOnLoadingComplete) {
          this.setState({ submitOnLoadingComplete: true, addressLoading: true });
        }
      } else {
        this.props.onChooseManualEntry && this.props.onChooseManualEntry(this.props.channel);
      }
    } else {
      this.setState({ addressLoading: true });

      fetch(this.buildPlacesUrl(result.prediction.place_id))
        .then((res) => res.json())
        .then((res: IAddressInputAddress) => {
          this.submit(res);
          this.props.onValidate && this.props.onValidate(true);
        })
        .catch((e) => {
          globalObservability.getSentryClient().captureException?.(e);
          this.setError();
          this.props.onValidate && this.props.onValidate(false);
        });
    }
  };

  private setError() {
    this.setState({ addressLoading: false });
  }

  private submit = (place: IAddressInputAddress) => {
    this.props.onSubmit && this.props.onSubmit(place, this.props.channel);
    this.props.shouldRedirectOnSubmit || this.setState({ addressLoading: true });
  };

  private doesQueryMatch = (
    prediction: google.maps.places.AutocompletePrediction,
    query: string,
  ) => {
    let matchedAtStart = false;
    // see how much of our query string matches the parts google has marked as matching
    const matchedLength = prediction.matched_substrings.reduce((total, substr) => {
      if (substr.offset == 0) {
        matchedAtStart = true;
      }
      // make sure matched_substring actually occurs in query
      if (query.indexOf(prediction.description.substr(substr.offset, substr.length)) !== -1) {
        return total + substr.length;
      }
      return total;
    }, prediction.matched_substrings.length - 1);

    if (
      // if we did not match the query from the beginning, send to manual
      !matchedAtStart ||
      // if we somehow get here and query is empty, go to manual entr
      query.length == 0 ||
      // if the match is < 80% the same, then send user to manual entry
      matchedLength / query.length < 0.8
    ) {
      return false;
    }
    return true;
  };

  private chooseFirstOrSelectedItem = () => {
    // selectedIndex is only set when the user uses arrow keys to select an item
    const { autocompleteOptions, selectedIndex } = this.state;
    const prediction = autocompleteOptions[selectedIndex] || autocompleteOptions[0];

    // Test to see if we have a good prediction match
    // This is to help avoid where users enter a search but we use the first
    // result even if it is a poor match.
    //
    // This can happen when a user enters an address that google does not know
    // about.
    //
    // If we have 4 or 5 matched sub strings then it is probably a good enough match
    if (selectedIndex == -1 && prediction && prediction.matched_substrings.length < 4) {
      if (!this.doesQueryMatch(prediction, this.state.query)) {
        this.chooseResult();
      }
    }

    // match looks decent enough or is undefined
    this.chooseResult(
      prediction && {
        displayValue: prediction.description,
        value: prediction.description,
        prediction,
      },
    );
  };

  private goToManualEntry = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onChooseManualEntry && this.props.onChooseManualEntry(this.props.channel);
  };

  private placeToOption = (prediction: AutocompletePrediction): AddressOption => {
    return {
      displayValue: prediction.description,
      value: prediction.description,
      prediction,
    };
  };

  private displayManualEntry = () => {
    const { designStyle } = this.props;
    return (
      <small>
        <InlineError className="inline-error" designStyle={designStyle}>
          We couldn't find that address. Try without zip codes or unit numbers.
          {this.props.hideErrorFormLink || (
            <>
              {' '}
              Or try our{' '}
              <InlineErrorLink
                analyticsName={`${this.props.analyticsName}-inline-error`}
                aria-label="Navigate to this link to manually submit an address"
                onClick={this.goToManualEntry}
              >
                simplified form
              </InlineErrorLink>
              .
            </>
          )}
        </InlineError>
      </small>
    );
  };
}
